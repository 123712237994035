import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentDiversity from "./TopComponentDiversity";
import { Tabs, Tab } from "react-bootstrap";
import DiversitySingleTimeSingleLoc from "./DiversitySingleTimeSingleLoc";
import DiversitySingleTimeMultLoc from "./DiversitySingleTimeMultLoc";
import DiversityMultiLocMultTimeDistri from "./FrameworkFourtyEight/DiversityMultLocMultTimeDistri";
import DiversityMultAge from "./FrameworkFourtyEight/DiversityMultAge";

const Diversity = ({
  locationOption,
  timePeriods,
  financialYearId,
  keyTab,

  graphData,
  frameworkValue,
}) => {
  const location = 2;
  const [lastWeekActivities, setLastWeekActivities] = useState({});
  const [timePeriodValues, setTimePeriodValues] = useState([]);

  const [employeeData, setEmployeeData] = useState({
    males: 0,
    females: 0,
    others: 0,
    maxConsumption: 0,
  });
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Employees": {
          number: `${employeeData.maxConsumption}`,
          questionId: [],
        },
        "Total Males": {
          number: `${employeeData.males}`,
          questionId: [],
        },
        "Total Females": {
          number: `${employeeData.females}`,
          questionId: [],
        },
        "Total Others": {
          number: `${employeeData.others}`,
          questionId: [],
        },
        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekActivities(data);
    }
  };

  const [empInclDif, setEmpInclDif] = useState([]);
  const [workInclDif, setWorkInclDif] = useState([]);
  const [empDif, setEmpDif] = useState([]);
  const [workDif, setWorkDif] = useState([]);
  const [diversity, setDiversity] = useState([]);
  const [companyFramework, setCompanyFramework] = useState([]);
  const [activebtnTab, setactivebtnTab] = useState(0);
  const [brief, setBrief] = useState();

  const handleTabClick = (index) => {
    setactivebtnTab(index);
  };

  useEffect(() => {
    // Update states based on filtered graphData and timePeriods
    if (companyFramework && companyFramework.includes(1)) {
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);

      const empDiffIncl =
        graphData?.filter(
          (item) =>
            item.title ===
            "Gender Diversity (including differently abled employees)"
        ) || [];
      const workDiffIncl =
        graphData?.filter(
          (item) =>
            item.title ===
            "Gender Diversity (including differently abled workers)"
        ) || [];
      const empDiff =
        graphData?.filter(
          (item) =>
            item.title === "Gender Diversity (diffferently abled employees)"
        ) || [];
      const workDiff =
        graphData?.filter(
          (item) =>
            item.title === "Gender Diversity (diffferently abled workers)"
        ) || [];
      const diver =
        graphData?.filter((item) => item.title === "Diversity in Leadership") ||
        [];

      const matchedDiffIncl =
        empDiffIncl.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const matchedWorkDiffIncl =
        workDiffIncl.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const matchedEmpDiff =
        empDiff.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const matchedWorkDiff =
        workDiff.filter((item) => {
          return Object.values(timePeriods || {}).includes(item?.formDate);
        }) || [];

      const finalEnergy = matchedDiffIncl.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyTwo = matchedWorkDiffIncl.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyThree = matchedEmpDiff.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyFouur = matchedWorkDiff.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      const finalEnergyFive = diver.filter((item) =>
        locationOption.some((location) => location.id === item.sourceId)
      );

      setEmpInclDif(finalEnergy);
      setWorkInclDif(finalEnergyTwo);
      setEmpDif(finalEnergyThree);
      setWorkDif(finalEnergyFouur);
      setDiversity(finalEnergyFive);

      let totalMales = 0;
      let totalFemales = 0;
      let totalOthers = 0;

      finalEnergy.forEach((item) => {
        const answers = item.answer || [[], []];

        // Add values from answers[0]
        if (answers[0]?.length === 3) {
          totalMales += parseInt(answers[0][0], 10) || 0;
          totalFemales += parseInt(answers[0][1], 10) || 0;
          totalOthers += parseInt(answers[0][2], 10) || 0;
        }

        // Add values from answers[1]
        if (answers[1]?.length === 3) {
          totalMales += parseInt(answers[1][0], 10) || 0;
          totalFemales += parseInt(answers[1][1], 10) || 0;
          totalOthers += parseInt(answers[1][2], 10) || 0;
        }
      });

      // Set the maximum consumption to the sum of all categories
      const maxConsumption = totalMales + totalFemales + totalOthers;

      setEmployeeData({
        males: totalMales,
        females: totalFemales,
        others: totalOthers,
        maxConsumption: maxConsumption,
      });
    } else if (companyFramework && companyFramework.includes(48)) {
      function convertMixedData(mixedArray) {
        return mixedArray.map((data) => {
          if (Array.isArray(data.answer) && Array.isArray(data.answer[0])) {
            const flattenedAnswer = data.answer.flat();
            const summedValue = flattenedAnswer.reduce(
              (sum, value) => sum + (parseFloat(value) || 0),
              0
            );

            return {
              questionId: data.questionId,
              sourceId: data.sourceId,
              answer: {
                process: 1,
                readingValue: summedValue.toString(),
                unit: "KG",
              },
              title: data.title,
              question_details: data.question_details,
              formDate: data.formDate,
              toDate: data.toDate,
            };
          } else {
            return {
              ...data,
              answer: {
                ...data.answer,
                readingValue: data.answer.readingValue || "0",
              },
            };
          }
        });
      }
      const timePeriodsArray = Object.values(timePeriods || []);
      setTimePeriodValues(timePeriodsArray);
      const valuesArray = locationOption
        ? locationOption.map((item) => item.unitCode || item.value)
        : [];

      const transformedKeys = Object.keys(timePeriods).map((key) =>
        key
      );

      const summary = {
        time: {},
        location: {},
      };

      if (locationOption) {
        locationOption.forEach((location) => {
          transformedKeys.forEach((quarter) => {
            summary.location[quarter] = {
              "Males out of total employees": new Array(
                locationOption.length
              ).fill(0),
              "Females out of total employees": new Array(
                locationOption.length
              ).fill(0),
              "Employees less than 30 years of age": new Array(
                locationOption.length
              ).fill(0),
              "Employees between 30-50 years of age": new Array(
                locationOption.length
              ).fill(0),
              "Employees more than 50 years of age": new Array(
                locationOption.length
              ).fill(0),
            };
          });
        });
      }

      if (transformedKeys) {
        transformedKeys.forEach((quarter) => {
          locationOption.forEach((location) => {
            summary.time[location.unitCode] = {
              "Males out of total employees": new Array(
                transformedKeys.length
              ).fill(0),
              "Females out of total employees": new Array(
                transformedKeys.length
              ).fill(0),
              "Employees less than 30 years of age": new Array(
                transformedKeys.length
              ).fill(0),
              "Employees between 30-50 years of age": new Array(
                transformedKeys.length
              ).fill(0),
              "Employees more than 50 years of age": new Array(
                transformedKeys.length
              ).fill(0),
            };
          });
        });
      }

      if (graphData) {
        const filteredData = graphData.filter(
          (item) =>
            item.questionId === 432 ||
            item.questionId === 433 ||
            item.questionId === 434 ||
            item.questionId === 435 ||
            item.questionId === 436
        );
        const convertedData = convertMixedData(filteredData);
        const timeKey = [];
        const locationKey = [];

        for (const period in summary.location) {
          timeKey.push(period);
        }

        for (const period in summary.time) {
          locationKey.push(period);
        }

        for (const location in summary.time) {
          const data = summary.time[location];
          for (const key in data) {
            for (let k = 0; k < summary.time[location][key].length; k++) {
              let time = timeKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.time[location][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
        for (const time in summary.location) {
          const data = summary.location[time];
          for (const key in data) {
            for (let k = 0; k < summary.location[time][key].length; k++) {
              let location = locationKey[k];
              const obj = locationOption.find(
                (item) => item.unitCode === location
              );
              const lowerCaseKey = time;
              const formDate = timePeriods[lowerCaseKey];
              const filterData = convertedData.find(
                (item) =>
                  item.title === key &&
                  item.formDate === formDate &&
                  item.sourceId === obj.id
              );
              summary.location[time][key][k] =
                Number(filterData?.answer?.readingValue) || 0;
            }
          }
        }
      }

      setBrief(summary);
      console.log(summary, "sdfgdsertd");
    }
  }, [graphData, timePeriods, companyFramework, locationOption]);

  useEffect(() => {
    lastWeekActivity();
  }, [employeeData]);

  useEffect(() => {
    if (frameworkValue?.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  const renderUI = () => {
    switch (activebtnTab) {
      case 1:
        return companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) ? (
          (timePeriodValues &&
            locationOption.length === 1 &&
            timePeriodValues.length === 1) ||
          (locationOption.length > 1 &&
            timePeriodValues.length === 1 &&
            keyTab === "combined") ? (
            <>
              <DiversitySingleTimeSingleLoc
                titleOne={"Total Permanant Workers Including Differently Abled"}
                dataOne={workInclDif}
                titleTwo={"Total Number Of Differently Abled Permanent Workers"}
                dataTwo={workDif}
                titleThree={"Women and Men in Leadership Roles"}
                titleFour={
                  "Total Non-Permanent Workers Including Differently Abled"
                }
                titleFive={
                  "Total Number Of Differently Abled Non-Permanent Workers"
                }
                diversity={diversity}
                companyFramework={companyFramework}
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                locationOption={locationOption}
                brief={brief}
              />
            </>
          ) : (locationOption.length > 1 &&
              timePeriodValues.length > 1 &&
              keyTab === "combined") ||
            (locationOption.length > 1 && timePeriodValues.length === 1) ||
            (locationOption.length === 1 && timePeriodValues.length > 1) ? (
            <>
              <DiversitySingleTimeMultLoc
                titleOne={"Total Permanant Workers Including Differently Abled"}
                dataOne={workInclDif}
                titleTwo={"Total Number Of Differently Abled Permanent Workers"}
                dataTwo={workDif}
                titleThree={"Women and Men in Leadership Roles"}
                titleFour={
                  "Total Non-Permanent Workers Including Differently Abled"
                }
                titleFive={
                  "Total Number Of Differently Abled Non-Permanent Workers"
                }
                diversity={diversity}
                companyFramework={companyFramework}
                timePeriods={timePeriods}
                timePeriodValues={timePeriodValues}
                brief={brief}
                locationOption={locationOption}
              />
            </>
          ) : (
            <>{/* Content goes here */}</>
          )
        ) : (timePeriodValues &&
            locationOption.length === 1 &&
            timePeriodValues.length === 1) ||
          (locationOption.length > 1 &&
            timePeriodValues.length === 1 &&
            keyTab === "combined") ? (
          <>
            <DiversitySingleTimeSingleLoc
              titleOne={"Total Permanant Workers Including Differently Abled"}
              dataOne={workInclDif}
              titleTwo={"Total Number Of Differently Abled Permanent Workers"}
              dataTwo={workDif}
              titleThree={"Percentage of Women and Men in Leadership Roles"}
              titleFour={
                "Total Non-Permanent Workers Including Differently Abled"
              }
              titleFive={
                "Total Number Of Differently Abled Non-Permanent Workers"
              }
              brief={brief}
              diversity={diversity}
              companyFramework={companyFramework}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
            />
          </>
        ) : (locationOption.length > 1 &&
            timePeriodValues.length > 1 &&
            keyTab === "combined") ||
          (locationOption.length > 1 && timePeriodValues.length === 1) ||
          (locationOption.length === 1 && timePeriodValues.length > 1) ? (
          <>
            <DiversitySingleTimeMultLoc
              titleOne={"Total Permanant Workers Including Differently Abled"}
              dataOne={workInclDif}
              titleTwo={"Total Number Of Differently Abled Permanent Workers"}
              dataTwo={workDif}
              titleThree={"Women and Men in Leadership Roles"}
              titleFour={
                "Total Non-Permanent Workers Including Differently Abled"
              }
              titleFive={
                "Total Number Of Differently Abled Non-Permanent Workers"
              }
              brief={brief}
              diversity={diversity}
              companyFramework={companyFramework}
              timePeriods={timePeriods}
              timePeriodValues={timePeriodValues}
              locationOption={locationOption}
            />
          </>
        ) : (
          timePeriodValues && (
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{
                  gap: "20px",
                  height: "75vh",
                  marginBottom: "3%",
                  width: "76vw",
                }}
              >
                <div className="nothing" style={{ width: "50%" }}>
                  <DiversityMultiLocMultTimeDistri brief={brief} />
                </div>
                <div className="nothing" style={{ width: "50%" }}>
                  <DiversityMultAge brief={brief} />
                </div>
              </div>
            </div>
          )
        );

      case 0:
        return companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) ? (
          (timePeriodValues &&
            locationOption.length === 1 &&
            timePeriodValues.length === 1) ||
          (locationOption.length > 1 &&
            timePeriodValues.length === 1 &&
            keyTab === "combined") ? (
            <>
              <DiversitySingleTimeSingleLoc
                titleOne={
                  "Total Permanant Employees Including Differently Abled"
                }
                dataOne={empInclDif}
                titleTwo={
                  "Total Number Of Differently Abled Permanent Employees"
                }
                dataTwo={empDif}
                titleThree={"Percentage of Women and Men in Leadership Roles"}
                titleFour={
                  "Total Non-Permanent Employees Including Differently Abled"
                }
                titleFive={
                  "Total Number Of Differently Abled Non-Permanent Employees"
                }
                diversity={diversity}
                companyFramework={companyFramework}
              />
            </>
          ) : (locationOption.length > 1 &&
              timePeriodValues.length > 1 &&
              keyTab === "combined") ||
            (locationOption.length > 1 && timePeriodValues.length === 1) ||
            (locationOption.length === 1 && timePeriodValues.length > 1) ? (
            <>
              <DiversitySingleTimeMultLoc
                titleOne={
                  "Total Permanant Employees Including Differently Abled"
                }
                dataOne={empInclDif}
                titleTwo={
                  "Total Number Of Differently Abled Permanent Employees"
                }
                dataTwo={empDif}
                titleThree={"Women and Men in Leadership Roles"}
                titleFour={
                  "Total Non-Permanent Employees Including Differently Abled"
                }
                titleFive={
                  "Total Number Of Differently Abled Non-Permanent Employees"
                }
                diversity={diversity}
                timePeriods={timePeriods}
                companyFramework={companyFramework}
              />
            </>
          ) : (
            <>{/* Content goes here */}</>
          )
        ) : (timePeriodValues &&
            locationOption.length === 1 &&
            timePeriodValues.length === 1) ||
          (locationOption.length > 1 &&
            timePeriodValues.length === 1 &&
            keyTab === "combined") ? (
          <>
            <DiversitySingleTimeSingleLoc timePeriodValues={timePeriodValues}  brief={brief} companyFramework={companyFramework} />
          </>
        ) : (locationOption.length > 1 &&
            timePeriodValues.length > 1 &&
            keyTab === "combined") ||
          (locationOption.length > 1 && timePeriodValues.length === 1) ||
          (locationOption.length === 1 && timePeriodValues.length > 1) ? (
          <>
            <DiversitySingleTimeMultLoc timePeriodValues={timePeriodValues} brief={brief} companyFramework={companyFramework} />
          </>
        ) : (
          timePeriodValues && (
            <div className="d-flex flex-column flex-space-between">
              <div
                className="d-flex flex-row flex-space-between"
                style={{
                  gap: "20px",
                  height: "75vh",
                  marginBottom: "3%",
                  width: "76vw",
                }}
              >
                <div className="nothing" style={{ width: "50%" }}>
                  <DiversityMultiLocMultTimeDistri brief={brief} />
                </div>
                <div className="nothing" style={{ width: "50%" }}>
                <DiversityMultAge brief={brief} />

                </div>
              </div>
            </div>
          )
        );

      default:
        return null;
    }
  };

  return (
    <div className="progress-container" style={{ width: "100%" }}>
  {/* Only render buttons if companyFramework does not include 48 */}
  {!companyFramework.includes(48) && (
    <div
      className="d-flex justify-content-between buttoncont"
     style={{ marginBottom: "25px", width: "25%", height: "6vh" }}
    >
      <button
        className={`btn button ${activebtnTab === 0 ? " activebtn" : ""}`}
        onClick={() => handleTabClick(0)}
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
          width: "45%",
          height: "100%",
        }}
      >
        Employee
      </button>
      <button
        className={`btn button ${activebtnTab === 1 ? " activebtn" : ""}`}
        onClick={() => handleTabClick(1)}
        style={{
          paddingLeft: "5vw",
          paddingRight: "5vw",
          width: "45%",
          height: "100%",
        }}
      >
        Worker
      </button>
    </div>
  )}
  
  {/* Render the rest of the UI */}
  {renderUI()}
</div>

  );
};

export default Diversity;
