import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import config from "../../config/config.json";
import "./working_progress.css";
import { apiCall } from "../../_services/apiCall";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../loader/Loader";
import ToggleButton from "react-toggle-button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReAssignQuestions from "../Company Sub Admin/Component/Sector Questions/ReassignUser";

const SubUsers = ({ userPermissionList }) => {
  const [updateLoader, setUpdateLoader] = useState(false);
  const [source_ids, setSource_ids] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [useLoader, setUseLoader] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [designationId, setDesignationId] = useState(null);
  const [showSkelton, setshowSkelton] = useState(false);
  const [userList, setUserList] = useState(null);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [usersId, setUsersId] = useState();
  const [removeUserLocationId, setRemoveUserLocationId] = useState();
  const [addNewUserPopup, setAddNewUserPopup] = useState(false);
  const [addNewLocationPopup, setAddNewLOcationPopup] = useState(false);
  const [designationList, setDesignationList] = useState(null);
  const [locationUsedIds, setLocationUsedIds] = useState([]);
  const [roleList, setRoleList] = useState();
  const [onlyAuditor, setOnlyAuditor] = useState(false);
  const [headOfficeTrue, setHeadOfficeTrue] = useState([]);
  const [headOfficeFalse, setHeadOfficeFalse] = useState([]);
  const [enable, setEnable] = useState();
  const [currentId, setCurrentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [reassignShow, setReassignShow] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [phone, setPhone] = useState("");
  const userId = JSON.parse(localStorage.getItem("user_temp_id"));
  const closeDeleteDesignationPopup = () => setDeleteModal(false);
  const handleReassignClose = () => setReassignShow(false);
  const handleReassignShow = (id) => {
    setUsersId(id);
    setReassignShow(true);
  };
  const handleCloseNewUserPopup = () => {
    setRoleId(null);
    setAddNewUserPopup(false);
  };
  const handleCloseLocationPopup = () => {
    setAddNewLOcationPopup(false);
  };
  const onRemoveSourceHandler = (removedItem) => {
    const updatedSelectedId = source_ids.filter(
      (item) => item !== removedItem[0].id
    );
    setSource_ids(updatedSelectedId);
  };

  const onSelectSourceHandler = (selectedItems) => {
    const selectedItemValues = selectedItems.map((item) => item.id);
    setSource_ids(selectedItemValues);
  };

  const handleShowNewLocationPopup = (id, location) => {
    setAddNewLOcationPopup(true);
    setUsersId(id);
    const locationIds = location.map((obj) => obj.sourceId);
    getLocationById(locationIds);
  };

  const handleShowNewUserPopup = () => {
    setAddNewUserPopup(true);
    getRoleById();
    getDesignationById();
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setUserDetails((userdetails) => {
      return { ...userdetails, [name]: value };
    });
  };

  const handleLocationId = async (inputData) => {
    const sourceIdsArray = [];
    inputData.forEach((item) => {
      if (item.location && Array.isArray(item.location)) {
        item.location.forEach((location) => {
          if (location.sourceId !== undefined) {
            sourceIdsArray.push(location.sourceId);
          }
        });
      }
    });
    return sourceIdsArray;
  };

  const getSubUser = async () => {
    setshowSkelton(true);
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSubUser`,
      {},
      { userId: userId },
      "GET"
    );
    setshowSkelton(false);
    if (isSuccess) {
      const locationIds = await handleLocationId(data?.data);
      setLocationUsedIds(locationIds);
      setUserList(data?.data?.reverse());
    }
  };

  const handleResendCredentials = async (userId) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}reSendCredicial`,
      {},
      { userId: userId },
      "POST"
    );
    if (isSuccess) {     
    }
  };

  

  const getLocationById = async (locationIds) => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      let locationList = data?.data?.reverse();
      const filteredArray = locationList.filter(
        (item) => !locationIds.includes(item.id)
      );
      locationList = filteredArray.map((item) => {
        const locationData = Object.values(item.location).join(", ");
        return { ...item, locationData };
      });
      setLocationList(locationList);
    }
  };

  const getRoleById = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getMasterData`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      setRoleList(data.data);
    }
  };

  const getDesignationById = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getDesignation`,
      {},
      { userId: userId },
      "GET"
    );
    if (isSuccess) {
      setDesignationList(data?.data?.reverse());
    }
  };

  const sendInvite = async (id, e) => {
    setUpdateLoader(true);
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `inviteSubUser`,
      {},
      {
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        emailId: userDetails?.emailId,
        mobileNumber: phone,
        invitedBy: Number(userId),
        designationId: Number(designationId),
        sourceId: JSON.stringify([]),
        roleId: Number(roleId),
      },
      "POST"
    );
    setUpdateLoader(false);
    if (isSuccess) {
      getSubUser();
      setAddNewUserPopup(false);
      setPhone("");
      setUserDetails({
        firstName: "",
        lastName: "",
        emailId: "",
      });
    }
  };

  const addLocation = async () => {
    setUpdateLoader(true);
    const { isSuccess, data, error } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `addLocationToUser`,
      {},
      {
        sourceId: JSON.stringify(source_ids),
        userId: usersId,
      },
      "POST"
    );
    setUpdateLoader(false);
    if (isSuccess) {
      handleCloseLocationPopup();
      getSubUser();
    }
  };

  const handleSearch = (searchTerm) => {
    // const trimmedSearchTerm = searchTerm.trim();
    // if (trimmedSearchTerm === "") {
    //   setFilterListValue([...processDataList]);
    // } else {
    //   const filteredResult = processDataList.filter((item) =>
    //     item.process.toLowerCase().includes(trimmedSearchTerm.toLowerCase())
    //   );
    //   setFilterListValue(filteredResult);
    // }
  };
  useEffect(() => {
    if (roleList?.length > 0) {
      const role = roleList.filter((role) => role.id === Number(roleId));
      console.log(role);
      const auditor = role[0]?.onlyauditor;
      setOnlyAuditor(auditor);
    }
  }, [roleId]);

  useEffect(() => {
    getSubUser();
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      const initialSelectedItem = userList[0];
      const headOfficeTrue = initialSelectedItem.location.filter(
        (location) => location.headOffice === true
      );
      const headOfficeFalse = initialSelectedItem.location.filter(
        (location) => location.headOffice === false
      );
      setHeadOfficeTrue(headOfficeTrue);
      setHeadOfficeFalse(headOfficeFalse);
      setSelectedRow(initialSelectedItem);
      setRemoveUserLocationId(initialSelectedItem?.userId);
    }
  }, [userList]);

  const handleRowClick = (userData) => {
    const headOfficeTrue = userData.location.filter(
      (location) => location.headOffice === true
    );
    const headOfficeFalse = userData.location.filter(
      (location) => location.headOffice === false
    );
    setSelectedRow(userData);
    setRemoveUserLocationId(userData?.userId);
    setHeadOfficeTrue(headOfficeTrue);
    setHeadOfficeFalse(headOfficeFalse);
  };
  const handleNoClick = () => {
    setShowModal(false);
  };
  const actionUser = async () => {
    const { isSuccess } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `actionOnSubUser`,
      {},
      { userId: currentId, status: enable },
      "POST"
    );
    if (isSuccess) {
      getSubUser();
      setCurrentId(null);
      setShowModal(false);
    }
  };
  const deleteLocation = async () => {
    const { isSuccess } = await apiCall(
      config.POSTLOGIN_API_URL_COMPANY + `removeLocation`,
      {},
      { userId: removeUserLocationId, locationId: currentId },
      "POST"
    );
    if (isSuccess) {
      getSubUser();
      setCurrentId(null);
      setDeleteModal(false);
    }
  };
  const handleToggle = (id, status) => {
    setEnable(!status);
    setShowModal(true);
    setCurrentId(id);
  };

  const removeLocation = (id) => {
    setDeleteModal(true);
    setCurrentId(id);
  };

  return (
    <div className="Introduction framwork_2">
      <section className="forms">
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="directly p-0 hstack gap-2 justify-content-end">
                <input
                  type="search"
                  className="form-control w-50"
                  placeholder="Search"
                  name="search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {userPermissionList.some(
                  (permission) =>
                    permission.permissionCode === "INVITE" && permission.checked
                ) && (
                  <button
                    className="new_button_style_white"
                    onClick={() => {
                      handleShowNewUserPopup();
                    }}
                  >
                    Add User
                  </button>
                )}
              </div>
            </div>
            <Row>
              <Col md={7} style={{ borderRight: "1px solid #bdc1c9" }}>
                <div className="table_setting">
                  <Table striped bordered hover className="m-0">
                    <thead>
                      <tr className="fixed_tr_section">
                        <th style={{ width: 55 }}>Sr</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th style={{ width: 55 }}>Status</th>
                        <th style={{ width: 55 }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!showSkelton ? (
                        userList && userList?.length > 0 ? (
                          userList?.map((data, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  background:
                                    selectedRow === data
                                      ? "rgb(31 158 209 / 35%)"
                                      : "",
                                  cursor: "pointer",
                                }}
                              >
                                <td
                                  onClick={() => handleRowClick(data)}
                                  className="text-center"
                                >
                                  {index + 1}{" "}
                                </td>
                                <td onClick={() => handleRowClick(data)}>
                                  {data && data?.firstName}
                                </td>
                                <td onClick={() => handleRowClick(data)}>
                                  {data && data?.lastName}
                                </td>
                                <td>
                                  <div
                                    className="text-center"
                                    title="Switch Status"
                                  >
                                    <ToggleButton
                                      value={data?.status}
                                      onToggle={() =>
                                        handleToggle(data?.userId, data?.status)
                                      }
                                    >
                                      {data?.status === 1
                                        ? "Active"
                                        : "Inactive"}
                                    </ToggleButton>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="text-center hstack gap-3"
                                    title="Add Location"
                                  >
                                    {userPermissionList.some(
                                      (permission) =>
                                        permission.permissionCode ===
                                          "ADDLOCATION" && permission.checked
                                    ) &&
                                      !data?.auditor && (
                                        <i
                                          className="fas fa-plus"
                                          onClick={() => {
                                            handleShowNewLocationPopup(
                                              data?.emailId,
                                              data?.location
                                            );
                                          }}
                                        ></i>
                                      )}
                                    {/* <i
                                      className="fas fa-retweet"
                                      title="Reassign"
                                      onClick={() =>
                                        handleReassignShow(data?.userId)
                                      }
                                    ></i> */}
                                    <i
                                      className="fas fa-envelope"
                                      title="Resend Credentials"
                                      onClick={() =>
                                        handleResendCredentials(data?.userId)
                                      }
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )
                      ) : (
                        <Loader />
                      )}
                    </tbody>
                  </Table>
                  <div className="Page navigation example"></div>
                </div>
              </Col>
              {!showSkelton ? (
                selectedRow && (
                  <Col md={5}>
                    <div className="table_setting">
                      <Table striped bordered hover>
                        <thead>
                          <tr className="fixed_tr_section">
                            <th style={{ width: 150 }}>Attribute</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Email_Id</td>
                            <td>{selectedRow?.emailId}</td>
                          </tr>
                          <tr>
                            <td>Mobile</td>
                            <td>{selectedRow?.mobileNumber}</td>
                          </tr>
                          <tr>
                            <td>Role</td>
                            <td>{selectedRow?.role}</td>
                          </tr>
                          <tr>
                            <td>Designation</td>
                            <td>{selectedRow?.designation}</td>
                          </tr>
                        </tbody>
                      </Table>
                      {headOfficeTrue.length > 0 && (
                        <Table bordered striped hover>
                          <thead>
                            <tr className="fixed_tr_section">
                              <th>Corporate Headquarters Address</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedRow.location?.map((location) => {
                              return (
                                <tr>
                                  {location?.headOffice === true && (
                                    <>
                                      <td>
                                        {`${location?.location?.area}, ${location?.location?.city}, ${location?.location?.state}, ${location?.location?.country}, ${location?.location?.zipCode}`}
                                      </td>
                                      <td
                                        className="text-center"
                                        title="Delete Location"
                                      >
                                        <i
                                          onClick={() =>
                                            removeLocation(location?.sourceId)
                                          }
                                          className="fas fa-trash-alt"
                                          style={{ color: "red" }}
                                        ></i>
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                      {headOfficeFalse.length > 0 && (
                        <Table bordered striped hover>
                          <thead>
                            <tr className="fixed_tr_section">
                              <th>Sr.</th>
                              <th>Regional Office Address</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedRow.location?.map((location, key) => {
                              return (
                                <tr>
                                  {location?.headOffice === false ? (
                                    <>
                                      <td>{key + 1}</td>
                                      <td>
                                        {`${location?.location?.area}, ${location?.location?.city}, ${location?.location?.state}, ${location?.location?.country}, ${location?.location?.zipCode}`}
                                      </td>
                                      <td
                                        className="text-center"
                                        title="Delete Location"
                                      >
                                        <i
                                          onClick={() =>
                                            removeLocation(location?.sourceId)
                                          }
                                          className="fas fa-trash-alt"
                                          style={{ color: "red" }}
                                        ></i>
                                      </td>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                      <div className="Page navigation example"></div>
                    </div>
                  </Col>
                )
              ) : (
                <Loader />
              )}
            </Row>
          </Col>
        </Row>
      </section>
      {reassignShow === true && (
        <ReAssignQuestions
          usersId={usersId}
          financialYearId={6}
          reassignShow={reassignShow}
          onHide={handleReassignClose}
          type="USERID"
        />
      )}
      <Modal show={addNewUserPopup} onHide={handleCloseNewUserPopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Form.Label>Add New User Account</Form.Label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="forms">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label fw-bold">
                    Enter User information
                  </label>
                  <Row>
                    <Col md={6}>
                      <div className="form-group pb-3">
                        <input
                          className="select_one industrylist"
                          aria-expanded="false"
                          placeholder="First Name"
                          name="firstName"
                          type="text"
                          onChange={handleChange}
                        />
                        {submitted && !userDetails.firstName && (
                          <div className="help-block">
                            First Name is Required
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group pb-3">
                        <input
                          className="select_one industrylist"
                          aria-expanded="false"
                          placeholder="Last Name"
                          name="lastName"
                          type="text"
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group pb-3">
                        <input
                          className="select_one industrylist"
                          aria-expanded="false"
                          placeholder="Email"
                          name="emailId"
                          type="email"
                          onChange={handleChange}
                        />
                        {submitted && !userDetails.emailId && (
                          <div className="help-block">Email is required</div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        enableSearch={true}
                      />
                    </Col>
                    <Col md={6}>
                      <div className="form-group pb-3">
                        <select
                          className="select_one industrylist"
                          onChange={(e) => {
                            setRoleId(e.target.value);
                          }}
                        >
                          <option value="">Select Role</option>
                          {roleList?.length > 0 &&
                            roleList?.map((data, index) => {
                              return (
                                <option key={index} value={data?.id}>
                                  {data?.role_name}
                                </option>
                              );
                            })}
                        </select>
                        {submitted && !userDetails.role_name && (
                          <div className="help-block">Role is Required</div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="form-group pb-3">
                        <select
                          className="select_one industrylist"
                          onChange={(e) => {
                            setDesignationId(e.target.value);
                          }}
                        >
                          <option value="">Select Designation</option>
                          {designationList?.length > 0 &&
                            designationList?.map((data, index) => {
                              return (
                                <option key={index} value={data?.id}>
                                  {data?.designation}
                                </option>
                              );
                            })}
                        </select>
                        {submitted && !userDetails.designation && (
                          <div className="help-block">
                            Designation is Required
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="new_button_style__reject"
            onClick={handleCloseNewUserPopup}
          >
            Cancel
          </button>
          {updateLoader ? (
            <button className="new_button_style" disabled>
              <Spinner animation="border" /> Sending...
            </button>
          ) : (
            <button
              type="submit"
              className="new_button_style"
              disabled={
                !userDetails?.firstName ||
                !userDetails?.emailId ||
                !designationId ||
                !roleId
              }
              onClick={(e) => sendInvite()}
            >
              Add User
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={addNewLocationPopup}
        onHide={handleCloseLocationPopup}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="forms">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label fw-bold">
                    Enter User Location
                  </label>
                  <Row>
                    <Col>
                      <div className="form-group pb-3">
                        <Multiselect
                          placeholder="Select Location"
                          displayValue="locationData"
                          className="multi_select_dropdown w-100"
                          options={locationList}
                          // ref={multiselectRef}
                          onRemove={onRemoveSourceHandler}
                          onSelect={onSelectSourceHandler}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleCloseNewUserPopup}>
            Cancel
          </Button>
          {updateLoader ? (
            <Button variant="info" disabled>
              <Spinner animation="border" /> Adding
            </Button>
          ) : (
            <Button
              variant="info"
              disabled={!source_ids.length}
              onClick={(e) => addLocation()}
            >
              Add Location
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {showModal && (
        <Modal show={showModal} onHide={handleNoClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {console.log(enable)}
              {enable ? (
                <>Activate Confirmation?</>
              ) : (
                <>Deactivate Confirmation?</>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {enable ? (
              <h5>Are you sure you want to Active your user status?</h5>
            ) : (
              <h5>Are you sure you want to Deactivate your user status?</h5>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleNoClick}>
              No
            </Button>
            {useLoader ? (
              <Button variant="info" disabled>
                <Spinner animation="border" />
              </Button>
            ) : (
              <Button variant="info" onClick={actionUser}>
                Yes
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={deleteModal} onHide={closeDeleteDesignationPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            type="submit"
            onClick={() => deleteLocation()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default SubUsers;
