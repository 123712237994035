import React, { useState, useEffect } from "react";
import { Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import Select, {components} from 'react-select';

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const FrequencyComponent = ({
  sourceData,
  answer,
  setAnswer,
  startingMonth,
  item,
  handlePeriodSelect,
  selectedPeriod,
  financialYear,
  setFromDate,
  setToDate,
  assignedToDetails,
  currentUserId,
  matchingAuditors,
  menu,
  setSourceChange,
}) => {
  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);
  const [timePeriod, setTimePeriod] = useState();
  const [timePeriodOptions, setTimePeriodOptions] = useState([]);




  const start = JSON.parse(localStorage.getItem("currentUser")).starting_month;


  const handlePeriodChange = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      selectedOptions = [];
    }

    setTimePeriod(selectedOptions);
    const year = parseInt(financialYear.split("-")[0]);

    let earliestFromDate = null;
    let latestToDate = null;

    // selectedOptions.forEach((period) => {
    //   let dateRange;

    //   if (identifier==="HALF YEARLY") {
    //     const sixMonthLater = (start + 6) % 12; // Wrap around December if necessary

    //     // Extract the first month from the selectedOption
    //     const firstMonthName = selectedOptions.split('-')[0];

    //     // Find the index of the first month from the months array
    //     const firstMonthIndex = months.indexOf(firstMonthName);

    //     // Check if the 6 months later month and first month match the condition
    //     const halfYear = (sixMonthLater === (firstMonthIndex + 1) % 12) ? 2 : 1;

    //     dateRange = calculateDateRange(
    //       6,
    //       halfYear,
    //       start,
    //       year
    //     );
    //   } else if (identifier==="QUARTERLY") {
    //     const firstMonthName = selectedOptions.split(' ')[0];

    //     // Find the index of the first month from the months array
    //     const firstMonthIndex = months.indexOf(firstMonthName);

    //     // Determine the quarter based on the start month and the selectedOption first month
    //     let quarter = Math.floor((start - 1) / 3) + 1; // Default quarter based on the start month

    //     // Adjust the quarter based on the first month in the selectedOption
    //     if (firstMonthIndex === start - 1) {
    //         // If the first month of selectedOption matches the start month, the quarter is based on start month
    //         quarter = Math.floor(firstMonthIndex / 3) + 1;
    //     }
    //     dateRange = calculateDateRange(
    //       3,
    //       quarter,
    //       start,
    //       year
    //     );
    //   } else if (identifier==="MONTHLY")
    //      {
    //     const firstMonthName = selectedOptions.split(' ')[0];

    //     // Find the index of the first month from the months array
    //     const firstMonthIndex = months.indexOf(firstMonthName)+1;
    //     dateRange = calculateDateRange(
    //       1,
    //       firstMonthIndex,
    //       start,
    //       year
    //     );
    //   } else if (period?.value === "Y1") {
    //     dateRange = calculateDateRange(12, 1, start, year);
    //   }

    //   if (dateRange) {
    //     // newTimePeriods[period?.value?.toLowerCase()] = dateRange?.fromDate;

    //     if (
    //       !earliestFromDate ||
    //       new Date(dateRange.fromDate) < new Date(earliestFromDate)
    //     ) {
    //       earliestFromDate = dateRange.fromDate;
    //     }
    //     if (
    //       !latestToDate ||
    //       new Date(dateRange.toDate) > new Date(latestToDate)
    //     ) {
    //       latestToDate = dateRange.toDate;
    //     }
    //   }
    // });

    let dateRange;

    if (item.answerFrequency === "HALF_YEARLY") {
      const sixMonthLater = (start + 6) % 12; // Wrap around December if necessary

      // Extract the first month from the selectedOption
      const firstMonthName = selectedOptions.label.split("-")[0].trim(); // Assuming `label` contains something like "Apr - Jun"

      // Find the index of the first month from the months array
      const firstMonthIndex = months.indexOf(firstMonthName);

      // Check if the 6 months later month and first month match the condition
      const halfYear = sixMonthLater === (firstMonthIndex + 1) % 12 ? 2 : 1;

      dateRange = calculateDateRange(6, halfYear, start, year);
    } else if (item.answerFrequency === "QUARTERLY") {
      // Extract the first month from the selectedOption
      const firstMonthName = selectedOptions.label.split(" ")[0].trim();

      // Find the index of the first month from the months array
      const firstMonthIndex = months.indexOf(firstMonthName);

      // Determine the quarter based on the start month and the selectedOption first month
      const quarter = Math.floor(firstMonthIndex / 3) + 1;

      dateRange = calculateDateRange(3, quarter, start, year);
    } else if (item.answerFrequency === "MONTHLY") {
      // Extract the first month from the selectedOption
      const firstMonthName = selectedOptions.label.split(" ")[0].trim();

      // Find the index of the starting month (start - 1 since arrays are 0-indexed)
      const startIndex = start - 1;

      // Find the index of the first month name in the months array
      const firstMonthAbsoluteIndex = months.indexOf(firstMonthName);

      // Calculate the relative index from the start
      const firstMonthIndex =
        ((firstMonthAbsoluteIndex - startIndex + months.length) %
          months.length) +
        1; // Add 1 to make it 1-based index

      dateRange = calculateDateRange(1, firstMonthIndex, start, year);
    } else if (item.answerFrequency === "YEARLY") {
      dateRange = calculateDateRange(12, 1, start, year);
    }

    if (dateRange) {
      // Update earliest and latest dates
      if (
        !earliestFromDate ||
        new Date(dateRange.fromDate) < new Date(earliestFromDate)
      ) {
        earliestFromDate = dateRange.fromDate;
      }
      if (
        !latestToDate ||
        new Date(dateRange.toDate) > new Date(latestToDate)
      ) {
        latestToDate = dateRange.toDate;
      }
    }

    setFromDate(earliestFromDate);
    setToDate(latestToDate);
    // setTimePeriods(newTimePeriods);
  };

  const calculateDateRange = (type, period, startingMonth, year) => {
    const startMonth = ((startingMonth - 1 + (period - 1) * type) % 12) + 1;
    const startYear =
      year + Math.floor((startingMonth - 1 + (period - 1) * type) / 12);
    const endMonth = ((startMonth - 1 + type) % 12) + 1;
    const endYear = startYear + Math.floor((startMonth - 1 + type) / 12);

    const formatDate = (month, year) =>
      `${year}-${month < 10 ? `0${month}` : month}`;

    return {
      fromDate: formatDate(startMonth, startYear),
      toDate: formatDate(endMonth, endYear),
    };
  };

 

  const [meterList, setMeterList] = useState([]);
  const formatDate = (year, month) => {
    const formattedMonth = month.toString().padStart(2, "0"); // Ensure two digits for the month
    return `${year}-${formattedMonth}`;
  };

  useEffect(() => {
    if (sourceData) {
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
        unitCode: item?.unitCode,
      }));
      if (locationArray && locationArray.length) {
        setMeterList(locationArray);
      
        setSourceChange(locationArray[0]?.id);
        setAnswer((prevAnswer) => ({
          ...prevAnswer,
          sourceId: locationArray[0]?.id,
        }));
      }
    }
  }, [sourceData]);



  const CustomOption = (props) => {
    const { isSelected, data } = props;

    const renderLabel = () => {
      if (data.label.includes(",")) {
        const words = data.label.split(",").map((word) => word.trim());
        const secondWord = words[1] || ""; // Get the second word or an empty string if it doesn't exist
        const fourthLastWord = words[words.length - 4] || ""; // Get the fourth last word or an empty string if it doesn't exist

        return (
          <div>
            {secondWord}, {fourthLastWord}{" "}
            {/* Render the second and fourth last words */}
          </div>
        );
      } else {
        return <div>{data.label}</div>; // Render data.label as is
      }
    };

    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            style={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                border: "2px solid #3f88a5",
                borderRadius: "2px",
                backgroundColor: isSelected ? "transparent" : "transparent",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Tick mark when selected */}
              {isSelected && (
                <span style={{ color: "white", fontSize: "14px" }}>✔</span>
              )}
            </div>
          </div>

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {renderLabel()} {/* Call the renderLabel function */}
          </div>
        </div>
      </components.Option>
    );
  };
  const CustomMultiValue = () => null;

  const CustomControl = (props) => {
    const { selectProps } = props;
    const { value, placeholder } = selectProps;

    // Function to render the label based on conditions
    const renderLabel = () => {
      if (value && value.length > 0) {
        const label = value[0].label;
        if (label.includes(",")) {
          const words = label.split(",").map((word) => word.trim());
          const secondWord = words[1] || ""; // Get the second word or an empty string if it doesn't exist
          const fourthLastWord = words[words.length - 4] || ""; // Get the fourth last word or an empty string if it doesn't exist
          return `${secondWord}, ${fourthLastWord}`.trim(); // Return the formatted label
        } else {
          return label; // Return the label as is
        }
      }
      return ""; // Return an empty string if no value
    };

    return (
      <components.Control {...props}>
        {/* Placeholder or selected value */}
        {(!value || value.length === 0) && (
          <div
            style={{
              color: "#3f88a5",
              fontWeight: 600,
              fontSize: "13px",
              position: "absolute",
              left: "5px",
              pointerEvents: "none",
            }}
          ></div>
        )}
        {/* Display the processed selected value */}
        {value && value.length > 0 && (
          <div style={{ color: "#3f88a5", marginLeft: "5px" }}>
            {renderLabel()} {/* Call the renderLabel function */}
          </div>
        )}

        {/* Ensure you still render the child components like the dropdown indicator and input */}
        {props.children}
      </components.Control>
    );
  };

  const CustomClearIndicator = () => null;

  const getDropdownItems = () => {
    if (!item?.answerFrequency) return null;

    const getCurrentYearMonth = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
      return `${year}-${month}`;
    };

    const isFromDateMatching = (fromDate) => {
      try {
        return matchingAuditors.some((auditor) => {
          const isFromDateMatch = auditor?.fromDate === fromDate;

          // Check if any remark contains the currentUserId
          let hasMatchingUserId = auditor?.remark?.some(
            (remark) => remark?.id == currentUserId 
          );

           const checker = ( Number(auditor?.auditerId) === Number(currentUserId))

          hasMatchingUserId = hasMatchingUserId && checker  ? false : hasMatchingUserId
    

          // Return true if the fromDate matches and no remark contains the currentUserId
          return isFromDateMatch && !hasMatchingUserId ;
        });
      } catch (error) {
        console.error("Error in isFromDateMatching:", error);
        return false;
      }
    };

  

    const isDisabled = (currentDate, fromDate, toDate) => {
      return currentDate < toDate;
    };

    const getCurrentDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const currentYearMonth = getCurrentYearMonth();
    switch (item.answerFrequency) {
      case "MONTHLY":
        if (menu === "audit") {
          if (financialYear) {
            const currentDate = getCurrentDate();
          
            return Array.from({ length: 12 }, (_, i) => {
              const month = `M${i + 1}`;
              const { fromDate, toDate } = calculateDates(month);
      
              // Check if the fromDate matches and no matching userId in remarks
              if (isFromDateMatching(fromDate)) {
                // Determine if the dropdown item should be disabled based on the current date and date range
                const disabled = isDisabled(currentDate, fromDate, toDate);
      
                return (
                  <Dropdown.Item key={month} eventKey={month} disabled={disabled}>
                    {month}
                  </Dropdown.Item>
                );
              }
              return null; // Exclude the month if conditions are not met
            }).filter(Boolean); // Remove null entries
          }
        } else {
          // If menu is not "audit", show all months M1 to M12
          // return Array.from({ length: 12 }, (_, i) => (
          //   <Dropdown.Item key={`M${i + 1}`} eventKey={`M${i + 1}`}>
          //     M{i + 1}
          //   </Dropdown.Item>
          // ));
          if(financialYear){
            const currentDate = getCurrentDate();
            return Array.from({ length: 12 }, (_, i) => {
              const month = `M${i + 1}`;
              const { fromDate, toDate } = calculateDates(month);
        
              const disabled = isDisabled(currentDate, fromDate, toDate);
        
              return (
                <Dropdown.Item key={month} eventKey={month} disabled={disabled}>
                  {month}
                </Dropdown.Item>
              );
            });

          }
         
        }

      case "QUARTERLY":
        if (menu === "audit") {
          if (financialYear) {
            // Calculate fromDate for each quarter
            const q1FromDate = calculateDates("Q1").fromDate;
            const q2FromDate = calculateDates("Q2").fromDate;
            const q3FromDate = calculateDates("Q3").fromDate;
            const q4FromDate = calculateDates("Q4").fromDate;

            const q1Dates = calculateDates("Q1");
            const q2Dates = calculateDates("Q2");
            const q3Dates = calculateDates("Q3");
            const q4Dates = calculateDates("Q4");

            const currentDate = getCurrentDate();

            return ["Q1", "Q2", "Q3", "Q4"]
              .filter((quarter) => {
                // Check if fromDate matches and there is no matching userId in remarks
                if (quarter === "Q1") return isFromDateMatching(q1FromDate);
                if (quarter === "Q2") return isFromDateMatching(q2FromDate);
                if (quarter === "Q3") return isFromDateMatching(q3FromDate);
                if (quarter === "Q4") return isFromDateMatching(q4FromDate);
              })
              .map((quarter) => {
                const dates =
                  quarter === "Q1"
                    ? q1Dates
                    : quarter === "Q2"
                    ? q2Dates
                    : quarter === "Q3"
                    ? q3Dates
                    : q4Dates;

                // Check if the item should be disabled based on the current date and date range
                const disabled = isDisabled(
                  currentDate,
                  dates.fromDate,
                  dates.toDate
                );

                return (
                  <Dropdown.Item
                    key={quarter}
                    eventKey={quarter}
                    disabled={disabled}
                  >
                    {quarter}
                  </Dropdown.Item>
                );
              });
          }
        } else {
          if(financialYear){
            const q1Dates = calculateDates("Q1");
          const q2Dates = calculateDates("Q2");
          const q3Dates = calculateDates("Q3");
          const q4Dates = calculateDates("Q4");

          const currentDate = getCurrentDate();
          return ["Q1", "Q2", "Q3", "Q4"].map((quarter) => {
            const dates =
              quarter === "Q1"
                ? q1Dates
                : quarter === "Q2"
                ? q2Dates
                : quarter === "Q3"
                ? q3Dates
                : q4Dates;

         
            const disabled = isDisabled(
              currentDate,
              dates.fromDate,
              dates.toDate
            );

            return (
              <Dropdown.Item
                key={quarter}
                eventKey={quarter}
                disabled={disabled}
              >
                {quarter}
              </Dropdown.Item>
            );
          });

          }
          
        }

      case "HALF_YEARLY":
        if (menu === "audit") {
          if (financialYear) {
            const h1FromDate = calculateDates("H1").fromDate;
            const h2FromDate = calculateDates("H2").fromDate;

            const h1Date = calculateDates("H1");
            const h2Date = calculateDates("H2");

            const currentDate = getCurrentDate();

            return ["H1", "H2"]
              .filter((halfYear) =>
                halfYear === "H1"
                  ? isFromDateMatching(h1FromDate)
                  : isFromDateMatching(h2FromDate)
              )
              .map((halfYear) => {
                const dates =
                  halfYear === "H1"
                    ? h1Date
                    : halfYear === "H2"
                    ? h2Date
                
                    : [];
    
             
                const disabled = isDisabled(
                  currentDate,
                  dates.fromDate,
                  dates.toDate
                );
    
                return (
                  <Dropdown.Item
                    key={halfYear}
                    eventKey={halfYear}
                    disabled={disabled}
                  >
                    {halfYear}
                  </Dropdown.Item>
                );
              });
          }
        } else {
          if (financialYear) {
            const h1Date = calculateDates("H1");
            const h2Date = calculateDates("H2");
            // If menu is not "audit", show both H1 and H2
  
            const currentDate = getCurrentDate();
            return ["H1", "H2"].map((halfYear) => {
              const dates =
                halfYear === "H1"
                  ? h1Date
                  : halfYear === "H2"
                  ? h2Date
              
                  : [];
  
           
              const disabled = isDisabled(
                currentDate,
                dates.fromDate,
                dates.toDate
              );
  
              return (
                <Dropdown.Item
                  key={halfYear}
                  eventKey={halfYear}
                  disabled={disabled}
                >
                  {halfYear}
                </Dropdown.Item>
              );
            });

          }
        
        }
      case "YEARLY":
        return (
          <Dropdown.Item key="Y1" eventKey="Y1">
            Y1
          </Dropdown.Item>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (item.answerFrequency) {
      let options = [];
      if (item.answerFrequency === "MONTHLY") {
        const options =
          start === 1
            ? months
            : [...months.slice(start - 1), ...months.slice(0, start - 1)];

        // Map options to { label, value } format
        const formattedOptions = options.map((month, index) => ({
          label: month,
          value: ((start + index - 1) % 12) + 1, // Ensures values cycle correctly from 1 to 12
        }));

        console.log("Monthly Options:", formattedOptions); // Debugging
        setTimePeriodOptions(formattedOptions); // Update state
      } else if (item.answerFrequency === "QUARTERLY") {
        for (let i = start - 1; i < start + 11; i += 3) {
          const quarterStartIndex = i % 12;
          const quarterEndIndex = (i + 3) % 12;
          const quarter = `${months[quarterStartIndex]} - ${
            months[(quarterEndIndex - 1 + 12) % 12]
          }`;
          options.push({ label: quarter, value: options.length + 1 });
        }
        setTimePeriodOptions(options);
      } else if (item.answerFrequency === "HALF_YEARLY") {
        for (let i = start - 1; i < start + 11; i += 6) {
          const halfStartIndex = i % 12;
          const halfEndIndex = (i + 6) % 12;
          const half = `${months[halfStartIndex]} - ${
            months[(halfEndIndex - 1 + 12) % 12]
          }`;
          options.push({ label: half, value: options.length + 1 });
          setTimePeriodOptions(options);
        }
      } else if (item.answerFrequency === "YEARLY") {
        const yearlyStartIndex = start - 1;
        options = [
          {
            label: `${months[yearlyStartIndex]} - ${
              months[(yearlyStartIndex - 1 + 12) % 12]
            }`,
            value: 1,
          },
        ];
        setTimePeriodOptions(options);
      }
    }
  }, [item, start]);

  const calculateMonthYear = (baseYear, baseMonth, monthOffset) => {
    const adjustedMonth = ((baseMonth - 1 + monthOffset) % 12) + 1;
    const yearOffset = Math.floor((baseMonth - 1 + monthOffset) / 12);
    const adjustedYear = baseYear + yearOffset;
    return { year: adjustedYear, month: adjustedMonth };
  };

  const calculateDates = (period) => {
    console.log(financialYear,"dippajdkdjs")
    const [startYear, endYear] = financialYear && financialYear?.split("-").map(Number);
    let baseYear = startYear;
    let baseMonth = startingMonth;

    let fromDate, toDate; // Declare variables to store fromDate and toDate

    switch (period) {
      // Monthly cases
      case "M1":
        const m1 = calculateMonthYear(baseYear, baseMonth, 0);
        fromDate = formatDate(m1.year, m1.month);
        toDate = formatDate(m1.year, m1.month + 1);
        break;

      case "M2":
        const m2 = calculateMonthYear(baseYear, baseMonth, 1);
        fromDate = formatDate(m2.year, m2.month);
        toDate = formatDate(m2.year, m2.month + 1);
        break;

      case "M3":
        const m3 = calculateMonthYear(baseYear, baseMonth, 2);
        fromDate = formatDate(m3.year, m3.month);
        toDate = formatDate(m3.year, m3.month + 1);
        break;

      case "M4":
        const m4 = calculateMonthYear(baseYear, baseMonth, 3);
        fromDate = formatDate(m4.year, m4.month);
        toDate = formatDate(m4.year, m4.month + 1);
        break;

      case "M5":
        const m5 = calculateMonthYear(baseYear, baseMonth, 4);
        fromDate = formatDate(m5.year, m5.month);
        toDate = formatDate(m5.year, m5.month + 1);
        break;

      case "M6":
        const m6 = calculateMonthYear(baseYear, baseMonth, 5);
        fromDate = formatDate(m6.year, m6.month);
        toDate = formatDate(m6.year, m6.month + 1);
        break;

      case "M7":
        const m7 = calculateMonthYear(baseYear, baseMonth, 6);
        fromDate = formatDate(m7.year, m7.month);
        toDate = formatDate(m7.year, m7.month + 1);
        break;

      case "M8":
        const m8 = calculateMonthYear(baseYear, baseMonth, 7);
        fromDate = formatDate(m8.year, m8.month);
        toDate = formatDate(m8.year, m8.month + 1);
        break;

      case "M9":
        const m9 = calculateMonthYear(baseYear, baseMonth, 8);
        fromDate = formatDate(m9.year, m9.month);
        toDate = formatDate(m9.year, m9.month + 1);
        break;

      case "M10":
        const m10 = calculateMonthYear(baseYear, baseMonth, 9);
        fromDate = formatDate(m10.year, m10.month);
        toDate = formatDate(m10.year, m10.month + 1);
        break;

      case "M11":
        const m11 = calculateMonthYear(baseYear, baseMonth, 10);
        fromDate = formatDate(m11.year, m11.month);
        toDate = formatDate(m11.year, m11.month + 1);
        break;

      case "M12":
        const m12 = calculateMonthYear(baseYear, baseMonth, 11);
        fromDate = formatDate(m12.year, m12.month);
        toDate = formatDate(m12.year, m12.month + 1);
        break;

      // Quarterly cases
      case "Q1":
        fromDate = formatDate(baseYear, baseMonth);
        toDate = formatDate(
          baseMonth + 3 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 3 > 12 ? baseMonth + 3 - 12 : baseMonth + 3
        );
        break;

      case "Q2":
        fromDate = formatDate(
          baseMonth + 3 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 3 > 12 ? baseMonth + 3 - 12 : baseMonth + 3
        );
        toDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        break;

      case "Q3":
        fromDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        toDate = formatDate(
          baseMonth + 9 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 9 > 12 ? baseMonth + 9 - 12 : baseMonth + 9
        );
        break;

      case "Q4":
        fromDate = formatDate(
          baseMonth + 9 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 9 > 12 ? baseMonth + 9 - 12 : baseMonth + 9
        );
        toDate = formatDate(
          baseYear + 1,
          baseMonth === 1 ? 1 : ((baseMonth - 1 + 12) % 12) + 1
        );
        break;

      // Half-yearly cases
      case "H1":
        fromDate = formatDate(baseYear, baseMonth);
        toDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        break;

      case "H2":
        fromDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        toDate = formatDate(
          baseYear + 1,
          baseMonth === 1 ? 1 : ((baseMonth - 1 + 12) % 12) + 1
        );
        break;

      // Yearly case
      case "Y1":
        fromDate = formatDate(baseYear, baseMonth);
        toDate = formatDate(baseYear + 1, baseMonth);
        break;

      default:
        fromDate = "";
        toDate = "";
        break;
    }

    return { fromDate, toDate };
  };

  useEffect(() => {
    if (selectedPeriod && financialYear) {
      const { fromDate, toDate } = calculateDates(selectedPeriod);

      // Only update the state if the values have changed
      setFromDate((prev) => (prev !== fromDate ? fromDate : prev));
      setToDate((prev) => (prev !== toDate ? toDate : prev));
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        fromDate:
          prevAnswer.fromDate !== fromDate ? fromDate : prevAnswer.fromDate,
        toDate: prevAnswer.toDate !== toDate ? toDate : prevAnswer.toDate,
      }));
    }
  }, [selectedPeriod]);

  return (
    <>
      <div style={{ width: "30%" }}>
        <div className="d-flex">
          {/* <div>
            {meterList?.length === 1 ? (
              <></>
            ) : (
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formInput10" style={styles.formGroup}>
                    <Form.Label className="custom-label">Source</Form.Label>
                    <div className="select-wrapper w-100">
                      <Dropdown onSelect={handleSourceSelect}>
                        {meterList?.length === 1 ? (
                          <div
                            style={{
                             backgroundColor:"#Dfebef",
                              color: "black",
                              borderColor: "white",
                              // width: "100%",
                              overflow: "auto",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                          >
                            {meterList[0]?.unitCode || meterList[0]?.location}
                          </div>
                        ) : (
                          <>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                
                              readOnly={
                                menu === "audit" ||
                                (assignedToDetails?.assignedTo?.length > 0 &&
                                  !assignedToDetails?.assignedTo?.some(
                                    (id) => parseInt(id, 10) === currentUserId
                                  ))
                              }
                              style={{
                               backgroundColor:"#Dfebef",
                                color: "black",
                                borderColor: "white",
                                // width: "100%",
                                overflow: "auto",
                              }}
                            >
                              <span style={{ marginRight: "5%" }}>
                                {sourceName ||
                                  selectedSource ||
                                  "Select Source"}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {meterList?.map((item) => (
                                <Dropdown.Item key={item.id} eventKey={item.id}>
                                  {item?.unitCode || item?.location}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </>
                        )}
                      </Dropdown>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div> */}
          <div className="mx-1">
            <Form.Group controlId="formInput10">
              <Form.Label className="custom-label">
                Reporting Period Selection
              </Form.Label>
              <div className="select-wrapper">
                {item?.frequency === "ONE_TIME" ||
                item?.frequency === "EVERY_FY" ? (
                  <Form.Control
                    type="text"
                    value={
                      item?.frequency === "ONE_TIME"
                        ? "One Time"
                        : item?.frequency === "EVERY_FY"
                        ? "Every Financial Year"
                        : item?.frequency
                    }
                    readOnly
                    style={{
                     backgroundColor:"#Dfebef",
                      color: "black",
                    }}
                  />
                ) : item?.frequency === "CUSTOM" ? (
                  <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "flex-start",
                  }}
                >
                
                  <Select
                    options={timePeriodOptions}
                    value={timePeriod}
                    onChange={(selectedOptions) => {
                      handlePeriodChange(selectedOptions);
                    }}
                    components={{
                      Option: CustomOption,
                      Control: CustomControl,
                      MultiValue: CustomMultiValue,
                      ClearIndicator: CustomClearIndicator,
                    }}
                    closeMenuOnSelect={false} // Prevent dropdown from closing
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "100%", // Make the container occupy 100% width
                      }),
                      control: (base) => ({
                        ...base,
                        border: "2px solid #3f88a5",
                        borderRadius: "10px",
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 100, // Ensure the menu appears above other elements
                        border: "2px solid #3f88a5",
                        borderRadius: "10px",
                      }),
                      dropdownIndicator: (base) => ({
                        ...base,
                        color: "#3f88a5", // Change color of the dropdown arrow
                        padding: "0 10px", // Adjust padding for the indicator
                        fontSize: "20px", // Increase the font size of the indicator
                        minHeight: "20px", // Set a minimum height for the indicator
                        minWidth: "20px", // Set a minimum width for the indicator
                      }),
                      placeholder: (base) => ({
                        ...base,
                        position: "absolute", // Ensure the placeholder doesn't shift with input
                        top: "50%",
                        transform: "translateY(-50%)", // Vertically center the placeholder
                        pointerEvents: "none", // Disable interaction on the placeholder
                      }),
                      multiValue: (base) => ({
                        ...base,
                        background: "transparent",
                        border: "2px solid #3f88a5",
                        borderRadius: "10px",
                        marginTop: "0.5em",
                      }),
                      option: (provided, state) => ({
                        ...provided,

                        backgroundColor: state.isSelected
                          ? "transparent" // Selected option background color
                          : state.isFocused
                          ? "white" // Focused option background color
                          : "white", // Default option background color
                        color: state.isSelected ? "black" : "black", // Text color based on state
                        cursor: "pointer",
                      }),
                    }}
                  />
                 
                </div>
                ) : null}
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "40%",
          textAlign: "right",
          marginTop: "25px",
        }}
      >
        <Button
          variant=""
          style={{
            padding: "8px 30px",
            marginRight: 0,
            backgroundColor: "transparent",
            borderRadius: "5px",
            borderColor: "#3F88A5",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: "700",
          }}
          onClick={() => console.log("History clicked")}
        >
          History
        </Button>
      </div>
    </>
  );
};

export default FrequencyComponent;
